import React from "react";

const HomePageTitle = () => {
  return (
    <>
  
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
              <h1>Grow your business with CodeWat</h1>
              <h2>Code With Advanced Technology</h2>
              <div className="d-flex justify-content-center justify-content-lg-start">
                <a href="/" className="btn-get-started scrollto">Get Started</a>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
              <img src={require('../../resources/images/Programming-pana.png')} className="img-fluid animated" alt="Responsive image"/>
            </div>
          </div>
        </div>
      </section>
    </>);
}

export default HomePageTitle;