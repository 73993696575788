import React from "react";

const ProjectSection = () => {
    return (
      <section id="services" className="services section-bg py-5" > 
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Our Products</h2>
          <p>Check out our products</p>
        </div>
    
        <div className="row justify-content-center">
          <div className="col-xl-2 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon-box">
              <img src={require("../../resources/images/Doctor Find.png")} className="img-fluid" alt="Responsive image" />
              <div className="icon"><i className="bi bi-1-circle"></i></div>
              <h4><a href="">Doctor Find Mobile App</a></h4>
              <p>Clinics at your fingertips. Find nearby clinics and available doctors and book appointment in minutes.</p>
            </div>
          </div>
    
          <div className="col-xl-2 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="400">
            <div className="icon-box">
              <img src={require("../../resources/images/dinvicard-logo.PNG")} className="img-fluid" alt="Responsive image" />
              <div className="icon"><i className="bx bx-layer"></i></div>
              <h4><a href="">Dinvicard</a></h4>
              <p>Crafting personalized digital invitations for every milestone. Celebrate life's moments with ease and creativity.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    

    
    );
}

export default ProjectSection;